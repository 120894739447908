import { Stack } from '@ds/coolshop';
import { ReactNode } from 'react';

type PimLayoutProps = {
    sidebar: ReactNode;
    content: ReactNode;
};

export const PimLayout = ({ sidebar, content }: PimLayoutProps) => (
    <Stack direction="row" height="100%">
        {sidebar}
        <Stack height="100%" width="75%" overflowY="auto" boxShadow={t => t.shadows[5]}>
            {content}
        </Stack>
    </Stack>
);
