import { Divider, FormControlLabel, IconButton, Stack, Switch, Typography } from '@ds/coolshop';
import { Pagination, SearchFilter, SearchFilterOperator } from '@src/__generated__/types';
import { encodeParam } from '@src/components/translations/Translations';
import { useNodeListLazyQuery } from '@src/modules/hierarchy/hierarchyQuery.generated';
import { SingleNodeView } from '@src/modules/hierarchy/SingleNodeView';
import { NodeEdgeArray, SelectedNode } from '@src/modules/hierarchy/types';
import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { TranslateToggleIcon } from '../hierarchies/TranslationComponents/CustomIcons/TranslateToggleIcon';
import { PimSideBar, PimSideBarSearch } from '../layout/PimSideBar';

interface ITranslationsNavigationProps {
    selectedNode: SelectedNode | undefined;
    setSelectedNode: Dispatch<SetStateAction<SelectedNode | undefined>>;
}

const first = 50;
const notApprovedFilter: SearchFilter = {
    field: 'approved',
    operator: SearchFilterOperator.NotEquals,
    value: ['true'],
};

export default function TranslationsNavigation(
    props: Readonly<ITranslationsNavigationProps>,
): JSX.Element {
    const { selectedNode, setSelectedNode } = props;
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const intl = useIntl();

    const [nodeListQuery, { loading }] = useNodeListLazyQuery({ fetchPolicy: 'no-cache' });

    const [hierarchyNode, setHierarchyNode] = useState<SelectedNode | undefined>();
    const [edges, setEdges] = useState<NodeEdgeArray>([]);
    const lastEdgeRef = useRef<HTMLDivElement | null>(null);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [showBreadcrumb, setShowBreadcrumb] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchFilter, setSearchFilter] = useState<SearchFilter | null>();

    const lastEdge = useMemo(() => (edges.length === 0 ? null : edges[edges.length - 1]), [edges]);

    const debouncedSearch = useCallback(
        debounce(
            val =>
                val.length
                    ? setSearchFilter({
                          field: 'listName',
                          operator: SearchFilterOperator.Contains,
                          value: [val],
                      })
                    : setSearchFilter(null),
            500,
        ),
        [],
    );

    const fetchNodes = async ({
        pagination,
        filter,
        hierarchyId,
        nodeId,
    }: {
        pagination: Pagination;
        filter: SearchFilter[];
        hierarchyId?: string;
        nodeId?: string;
    }) => {
        const { data } = await nodeListQuery({
            variables: {
                isInTranslation: true,
                pagination,
                filter,
                id: hierarchyId,
                nodeId,
            },
        });
        if (!data) return;
        const { nodeList } = data;
        const { nodes, currentNode } = nodeList;
        if (currentNode) {
            const node = {
                code: currentNode.code,
                hierarchy: currentNode.hierarchy,
                id: currentNode.id,
                parent: currentNode.parent,
                parentCode: currentNode.parentCode,
                contentTypeGroupStatus: currentNode.contentTypeGroupStatus ?? [],
                listName: currentNode.listName ?? '',
                readRoles: currentNode.readRoles ?? [],
                writeRoles: currentNode.writeRoles ?? [],
            };
            if (hierarchyId === nodeId && !hierarchyNode) {
                setHierarchyNode(node);
            }
        } else {
            setHierarchyNode(undefined);
            setSelectedNode(undefined);
            setHasNextPage(false);
        }

        setHasNextPage(!!nodeList.nodes.pageInfo.hasNextPage);
        setEdges(prev => {
            const curIds = new Set(prev.map(e => e.node.id));
            if (currentNode?.code === hierarchyNode?.code || !currentNode) {
                return prev.concat(nodes.edges.filter(e => !curIds.has(e.node.id)));
            }
            return nodes.edges;
        });
    };

    // fetch more nodes on scroll
    async function fetchMore(search?: SearchFilter | null): Promise<void> {
        if (!lastEdge || !hasNextPage) {
            // nothing to do
            return;
        }

        const filter = [notApprovedFilter];
        if (search) filter.push(search);

        fetchNodes({
            hierarchyId: hierarchyNode?.hierarchy,
            nodeId: hierarchyNode?.id,
            pagination: {
                first,
                after: lastEdge.cursor,
            },
            filter,
        });
    }

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (!entry.isIntersecting || !lastEdge || !lastEdgeRef.current || !hasNextPage) {
                    return;
                }
                fetchMore(searchFilter);
            });
        });

        if (lastEdgeRef.current) {
            observer.observe(lastEdgeRef.current);
        }

        return function cleanup() {
            if (lastEdgeRef.current) {
                observer.unobserve(lastEdgeRef.current);
            }
        };
    }, [edges, searchFilter, hasNextPage, lastEdge, lastEdgeRef]);

    const setLastElementRef = useCallback((node: HTMLDivElement | null) => {
        if (node) lastEdgeRef.current = node;
    }, []);

    // fetch nodes on pathname changed
    useEffect(() => {
        const [encodedHierarchyId, encodedNodeId] = pathname.split('/').slice(2);
        const hierarchyId = encodedHierarchyId
            ? decodeURIComponent(atob(encodedHierarchyId))
            : undefined;
        const nodeId = encodedNodeId ? decodeURIComponent(atob(encodedNodeId)) : undefined;

        if (!hierarchyId && !nodeId) {
            setEdges([]);
            setSearchValue('');
            debouncedSearch('');
            fetchNodes({ pagination: { first }, filter: [notApprovedFilter] });
        } else if (!hierarchyNode && hierarchyId && nodeId) {
            setSearchValue('');
            debouncedSearch('');
            fetchNodes({ pagination: { first }, filter: [notApprovedFilter], hierarchyId, nodeId });
        }
    }, [pathname, hierarchyNode, notApprovedFilter]);

    useEffect(() => {
        setEdges([]);
        if (searchFilter) {
            fetchNodes({
                pagination: { first },
                filter: [notApprovedFilter, searchFilter],
                hierarchyId: hierarchyNode?.hierarchy,
                nodeId: hierarchyNode?.id,
            });
        } else {
            fetchNodes({
                pagination: { first },
                filter: [notApprovedFilter],
                hierarchyId: hierarchyNode?.hierarchy,
                nodeId: hierarchyNode?.id,
            });
        }
    }, [searchFilter, notApprovedFilter]);

    return (
        <PimSideBar
            loading={loading}
            topComponent={
                <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        {!hierarchyNode ? (
                            <Typography variant="h6" color="secondary">
                                {intl.formatMessage({
                                    id: 'translations.navbar-title',
                                    defaultMessage: 'Hierarchies to be approved',
                                })}
                            </Typography>
                        ) : (
                            <>
                                <IconButton
                                    color="primary"
                                    onClick={() => navigate('/translations')}
                                >
                                    <TranslateToggleIcon />
                                </IconButton>
                                <FormControlLabel
                                    label={
                                        <Typography variant="caption">
                                            {intl.formatMessage({
                                                id: 'translations.show-breadcrumb',
                                                defaultMessage: 'Show breadcrumb',
                                            })}
                                        </Typography>
                                    }
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            checked={showBreadcrumb}
                                            onChange={() => setShowBreadcrumb(prev => !prev)}
                                        />
                                    }
                                />
                            </>
                        )}
                    </Stack>
                    {hierarchyNode && (
                        <Stack direction="row" justifyContent="space-between">
                            <Typography color={theme => theme.palette.primary.main}>
                                {hierarchyNode?.listName}
                            </Typography>
                        </Stack>
                    )}

                    <Divider />

                    <PimSideBarSearch
                        value={searchValue}
                        onChange={e => {
                            setSearchValue(e.target.value);
                            debouncedSearch(e.target.value);
                        }}
                    />
                </>
            }
            bottomComponent={edges.map((edge, index) => {
                const isLastEdge = index === edges.length - 1;
                return (
                    <SingleNodeView
                        key={edge.node.id}
                        selectedNodeId={selectedNode?.id}
                        showCtgStatus={false}
                        edge={edge}
                        mode="hierarchy-viewer"
                        ref={isLastEdge ? setLastElementRef : null}
                        isInTranslation
                        showChildren={false}
                        showBreadcrumb={showBreadcrumb && edge.node.hierarchy !== edge.node.id}
                        onEdgeSelected={selectedEdge => {
                            if (
                                selectedEdge.node.hierarchy === selectedEdge.node.id &&
                                !pathname.split('/').slice(2).length
                            ) {
                                navigate(
                                    `${encodeParam(selectedEdge.node.hierarchy)}/${encodeParam(
                                        selectedEdge.node.id,
                                    )}`,
                                );
                            } else {
                                setSelectedNode({
                                    code: selectedEdge.node.code,
                                    hierarchy: selectedEdge.node.hierarchy,
                                    id: selectedEdge.node.id,
                                    parent: selectedEdge.node.parent,
                                    parentCode: selectedEdge.node.parentCode,
                                    contentTypeGroupStatus:
                                        selectedEdge.node.contentTypeGroupStatus ?? [],
                                    listName: selectedEdge.node.listName ?? '',
                                    writeRoles: selectedEdge.node.writeRoles ?? [],
                                });
                            }
                        }}
                    />
                );
            })}
        />
    );
}
