import { SelectedNode } from '@src/modules/hierarchy/types';
import { SettingsContext } from '@src/modules/setting/SettingsContext';
import { useContext, useState } from 'react';

import { PimLayout } from '../layout/PimLayout';
import TranslateNode from './TranslateNode';
import TranslationsNavigation from './TranslationsNavigation';

export const path = 'translations';

export const encodeParam = (param: string) => btoa(encodeURIComponent(param));

export function useTranslations() {
    const { enableContentTranslation } = useContext(SettingsContext);
    return {
        canTranslate: enableContentTranslation, // TODO: check if user can translate
    };
}

export default function Translations(): JSX.Element {
    const [selectedNode, setSelectedNode] = useState<SelectedNode>();

    return (
        <PimLayout
            sidebar={
                <TranslationsNavigation
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                />
            }
            content={<TranslateNode selectedNode={selectedNode} />}
        />
    );
}
