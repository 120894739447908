import {
    Chip,
    Stack,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from '@mui/material';
import { ContentTypeGroupStatus, CtgStatus } from '@src/__generated__/types';
import React from 'react';

type ContentTypeGroupStatusesProps = {
    status: ContentTypeGroupStatus[] | undefined | null;
};

const HtmlTooltip = styled(({ className, ...styleProps }: TooltipProps) => (
    <Tooltip {...styleProps} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export function ContentTypeGroupStatuses(props: ContentTypeGroupStatusesProps) {
    const { status } = props;

    const [notPublished, draft, published] = React.useMemo(() => {
        if (!status) {
            return [[], [], []];
        }

        const notPublishedCtg =
            status.filter(singleStatus => singleStatus.status === CtgStatus.NotPublishable) ?? [];

        const draftCtg =
            status.filter(
                ({ status: ctgStatus }) =>
                    ctgStatus === CtgStatus.Draft || ctgStatus === CtgStatus.PublishedDraft,
            ) ?? [];

        const publishedCtg =
            status.filter(singleStatus => singleStatus.status === CtgStatus.Published) ?? [];

        return [notPublishedCtg, draftCtg, publishedCtg];
    }, [status]);

    return (
        <Stack flexDirection="row" alignItems="center" gap={0.5}>
            {notPublished.length > 0 && (
                <HtmlTooltip
                    disableHoverListener={notPublished.length === 0}
                    title={
                        <Stack
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            gap={1}
                        >
                            <Typography color="secondary.main" fontWeight="bold" fontSize="13px">
                                Not published
                            </Typography>
                            {notPublished.map(singleStatus => (
                                <Typography
                                    key={singleStatus.code}
                                    color="inherit"
                                    fontWeight="bold"
                                    fontSize="12px"
                                >
                                    {singleStatus.code}
                                </Typography>
                            ))}
                        </Stack>
                    }
                    placement="top"
                >
                    <Chip
                        label={notPublished.length}
                        size="small"
                        variant="outlined"
                        sx={{
                            backgroundColor: '#f78888',
                            color: '#b30202',
                            borderColor: '#b30202',
                        }}
                    />
                </HtmlTooltip>
            )}

            {draft.length > 0 && (
                <HtmlTooltip
                    disableHoverListener={draft.length === 0}
                    title={
                        <Stack
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            gap={1}
                        >
                            <Typography color="secondary.main" fontWeight="bold" fontSize="13px">
                                Draft
                            </Typography>
                            {draft.map(singleStatus => (
                                <Typography
                                    key={singleStatus.code}
                                    color="inherit"
                                    fontWeight="bold"
                                    fontSize="12px"
                                >
                                    {singleStatus.code}
                                </Typography>
                            ))}
                        </Stack>
                    }
                    placement="top"
                >
                    <Chip
                        label={draft.length}
                        size="small"
                        variant="outlined"
                        sx={{
                            backgroundColor: theme => theme.palette.grey[200],
                            color: 'darkslategray',
                        }}
                    />
                </HtmlTooltip>
            )}

            <HtmlTooltip
                disableHoverListener={published.length === 0}
                title={
                    <Stack direction="column" alignItems="center" justifyContent="center" gap={1}>
                        <Typography color="secondary.main" fontWeight="bold" fontSize="13px">
                            Published
                        </Typography>
                        {published.map(singleStatus => (
                            <Typography
                                key={singleStatus.code}
                                color="inherit"
                                fontWeight="bold"
                                fontSize="12px"
                            >
                                {singleStatus.code}
                            </Typography>
                        ))}
                    </Stack>
                }
                placement="top"
            >
                <Chip
                    label={published.length}
                    size="small"
                    variant="outlined"
                    sx={{
                        backgroundColor: published.length === 0 ? '' : 'lightskyblue',
                        color:
                            published.length === 0
                                ? theme => theme.palette.secondary.main
                                : theme => theme.palette.primary.main,
                        opacity: published.length === 0 ? '0.5' : '1',
                        borderColor:
                            published.length === 0
                                ? theme => theme.palette.secondary.main
                                : theme => theme.palette.primary.main,
                    }}
                />
            </HtmlTooltip>
        </Stack>
    );
}

export default ContentTypeGroupStatuses;
